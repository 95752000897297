<template>
  <a-modal
    title="社区二维码"
    :width="600"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @cancel="cancelForm"
    :footer="null"
  >
    <div style="text-align: center">
      <a-spin :spinning="loading">
        <img :src="codeUrl" />
      </a-spin>
    </div>
  </a-modal>
</template>
<script>
import { communityCodeUrl } from '@/api/community'
export default {
  name: 'ClassifyInfo',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      queryParam: {
        communityId: ''
      },
      codeUrl: ''
    }
  },
  created () {
  },
  methods: {
    ininData (data) {
      console.log('ininData', data)
      this.queryParam.communityId = data.id
      this.codeUrl = ''
      this.saveDataApi()
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    saveDataApi () {
      const _this = this
      _this.loading = true
      console.log(_this.queryParam)
      communityCodeUrl(_this.queryParam).then((res) => {
        _this.codeUrl = res.result.codeUrl
        _this.loading = false
        console.log(res)
      }).catch((err) => {
        _this.loading = false
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
